<!--
// nuxt-ui/components/pages/region-home/ItalyLandingHome.vue
-->
<script setup lang="ts">
import BgItalyLanding from "~/nuxt-ui/assets/images/background-landing-italy.jpg";
import LogoKingsItaly from "~/nuxt-ui/assets/images/logo-kings-league-italy.png";

const { locale } = useI18n();
</script>

<template>
  <section
    class="min-w-full min-h-screen md:min-h-[calc(100vh-225px)] bg-cover bg-center bg-no-repeat"
    :style="`background-image:url('${BgItalyLanding}');`"
  >
    <div class="kql-w-container grid grid-cols-1 md:grid-cols-[40%_60%] gap-8 p-6 md:gap-10 md:px-10">
      <div
        class="flex flex-col items-center justify-center gap-4 md:gap-6 h-auto md:min-h-[calc(100vh-225px)]"
      >
        <img
          :src="LogoKingsItaly"
          alt="Kings League Italia"
          width="210"
          height="256"
          class="w-[180px] md:w-auto max-w-full h-auto"
        />
        <div class="text-xl md:text-2xl text-primary uppercase font-bold italic">Tryouts coming soon</div>
      </div>
      <div
        class="flex flex-col items-center md:items-start justify-center h-auto md:min-h-[calc(100vh-225px)] max-w-full pr-0 md:pr-20"
      >
        <iframe
          width="771"
          height="442px"
          class="max-w-[771px] aspect-video w-full h-auto rounded-3xl"
          src="https://www.youtube-nocookie.com/embed/E9TC65bVMSQ?si=iJvs2Z7Op3VNsO_S"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
